<template>
  	<div>
        <!--begin::Charts Widget-->
		<div class="card card-custom card-stretch">
			<!--begin::Header-->
			<div v-if="title" class="card-header h-auto border-0">
				<!--begin::Title-->
				<div class="card-title py-5">
					<h3 class="card-label">
						<span class="d-block text-dark font-weight-bolder">{{ title | title }}</span>
						<span class="d-block text-muted mt-2 font-size-sm">{{ deskripsi }}</span>
					</h3>
				</div>
				<!--end::Title-->
			</div>
			<!--end::Header-->
			<!--begin::Body-->
			<div class="card-body">
				<!--begin::Chart-->
				<apexchart
					:options="chartOptions"
					:series="series"
        			type="bar"
				></apexchart>
				<!--end::Chart-->
			</div>
			<!--end::Body-->
		</div>
		<!--end::Charts Widget-->
  	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        title:{
            type: String,
            default: () => "Bar Chart"
        },
        deskripsi:{
            type: String,
            default: () => ""
        },
        setSeries:{
            type: Array,
            default: () => []
        },
        categories:{
            type: Array,
            default: () => []
        },
        colors:{
            type: Array,
            default: () => ["#8576FF", "#00E0D1", "#FEBA6A", "#FE708B", "#EF6AE7", "#6993FF", "#18AFA7", "#8950FC", "#E67300", "#FF475A"]
        },
    },
	data() {
		return {
			chartOptions: {},
			series: [],
		}
	},
	computed: {
		...mapGetters(["layoutConfig"])
	},
	mounted() {
		this.series = this.setSeries

		this.chartOptions = {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: true
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
					borderRadius: 5,
                    columnWidth: ['55%'],
                    endingShape: 'rounded'
                },
            },
            legend: {
                show: false
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: this.categories,
                axisBorder: {
                    show: false,
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: this.layoutConfig("colors.gray.gray-500"),
                        fontSize: '12px',
                        fontFamily: this.layoutConfig("font-family")
                    }
                }
            },
            yaxis: {
                labels: {
                    style: {
                        colors: this.layoutConfig("colors.gray.gray-500"),
                        fontSize: '12px',
                        fontFamily: this.layoutConfig("font-family")
                    }
                }
            },
            fill: {
                opacity: 1
            },
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0
                    }
                }
            },
            tooltip: {
                style: {
                    fontSize: '12px',
                    fontFamily: this.layoutConfig("font-family")
                },
            },
            colors: this.colors,
            grid: {
                borderColor: this.layoutConfig("colors.gray.gray-200"),
                strokeDashArray: 4,
                yaxis: {
                    lines: {
                        show: true
                    }
                }
            }
        }
	},
}
</script>